import React from 'react'
import Section6 from '../HomePage/Layout/Section6/Section6'

const Service = () => {
  return (
    <div>
      <Section6/>
    </div>
  )
}

export default Service
